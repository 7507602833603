import get from 'lodash/get';
import PageBackground from 'components/atoms/PageBackground/PageBackground';
import Header from 'components/organisms/Header/Header';
import { Footer } from 'components/organisms/Footer';
import { PageTemplateProps } from 'types/pages';
import ComponentsRenderer from './partials/ComponentsRenderer';
import TemplateWrapper from './partials/TemplateWrapper';
import { HeaderSpacer } from 'components/atoms/HeaderSpacer';

const PageTemplate: React.FC<PageTemplateProps> = ({ data, header, footer }) => {
  return (
    <TemplateWrapper pageId={data?.id} data={data}>
      <PageBackground />
      <Header {...header} alternateLanguages={data?.alternate_languages ?? []} />
      <HeaderSpacer />
      <ComponentsRenderer slices={get(data, 'data.body', [])} />
      <Footer data={get(footer, 'data.body', [])} />
    </TemplateWrapper>
  );
};

export default PageTemplate;
