
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[domain]/[[...slug]]",
      function () {
        return require("private-next-pages/[domain]/[[...slug]].tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[domain]/[[...slug]]"])
      });
    }
  