
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { NextPageProps } from 'types/pages';
import { fetchDocumentContent } from 'prismic/helpers/fetchContent';
import { getDocumentRelationsFromFile } from 'prismic/getDocumentRelations';
import { getPageTypeFromSlug } from 'prismic/helpers/getPageTypeFromSlug';
import { DOMAINS, DomainType, LOCALES } from 'prismic/config';
import PageTemplate from 'components/templates/PageTemplate';
import { mapNextLocaleToISOLanguageCode } from 'helpers/locale.helper';
import { getLastFromSlug } from 'helpers/path.helper';

const Page: NextPageProps = ({ header, data, footer }) => {
  return <PageTemplate data={data} header={header} footer={footer} />;
};

export async function getStaticPaths(): Promise<any> {
  const paths: Array<string | { params: any; locale?: string }> = [];

  DOMAINS.forEach((domain) => {
    Object.values(LOCALES).forEach((locale) => {
      if (domain === 'ballenbergkurse.ch' && locale !== 'de') {
        return;
      }

      paths.push({
        params: { domain, slug: [] },
        locale,
      });
    });
  });
  return { paths, fallback: 'blocking' };
}

 async function _getStaticProps({ params, locale }: any) {
  const { slug, domain } = params;

  const isoLangCode = mapNextLocaleToISOLanguageCode(locale);
  const uid = getLastFromSlug(slug) || '';
  const pageType = getPageTypeFromSlug(slug);

  const documentRelations = await getDocumentRelationsFromFile();

  const fetchedContent = await fetchDocumentContent({
    lang: isoLangCode,
    domain: domain as DomainType,
    isValidPath: true,
    uid,
    pageType,
  });

  if (!fetchedContent) {
    console.error(`No content found ->`, {
      lang: isoLangCode,
      domain: domain as DomainType,
      isValidPath: true,
      uid,
      pageType,
    });

    return {
      notFound: true,
    };
  }

  const { data, header, footer, type } = fetchedContent;

  return {
    props: {
      type,
      header,
      footer,
      data,
      documentRelations: documentRelations,
      sharedData: null,
      domain: domain as DomainType,
    },
  };
}

export default Page;


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/[domain]/[[...slug]]',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  