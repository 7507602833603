import { useContext } from 'react';
import { useRouter } from 'next/router';
import { media, styled } from '@nx-kit/styling';
import { COURSE_DOMAIN, DEFAULT_DOMAIN, GUIDE_BASE } from 'prismic/config';
import { DomainContext } from 'contexts/domain/DomainContext';

export const BgMotivStyled = styled.div`
  position: absolute;
  height: 500px;
  width: 100%;
  z-index: -1;

  img {
    position: absolute;
    right: -100px;
    top: 0;
    height: 500px;
    ${media('md')} {
      right: 0;
      height: 700px;
    }
  }
`;

const SingleImageStyled = styled.div`
  position: absolute;
  top: 100px;
  width: 100%;
  height: 900px;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: auto 630px;

  ${media('md')} {
    background-position: 80% 60px;
    background-size: auto 720px;
  }

  ${media('lg')} {
    background-size: cover;
    background-position: center;
  }
`;

const BGImage: React.FC = () => {
  const { asPath } = useRouter();
  const {
    computed: { isCourse },
  } = useContext(DomainContext);

  if (
    asPath === '/' ||
    asPath === `/${DEFAULT_DOMAIN}` ||
    asPath === `/${COURSE_DOMAIN}` ||
    asPath === `/${GUIDE_BASE}`
  ) {
    return (
      <SingleImageStyled
        data-type="motiv"
        style={{
          backgroundImage: isCourse
            ? "url('/motivs/scherenschnitte_kurse.jpg')"
            : "url('/motivs/scherenschnitte_hero_home.jpg')",
        }}
      />
    );
  }

  return null;
};

export default BGImage;
